.cardContainer {
  /* border: 1px solid red; */
  /* padding: 10px; */
}
.showCard {
  border: 1px solid rgb(80, 79, 79);
  border-radius: 4px;
  padding: 20px;
}

/* fonts */
.editStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* fonts */

.font20 {
  font-size: 18px;
  font-weight: 550;
}

.react-datepicker__input-container input{
    padding:7px;
    font-size: 18px;
}

.react-datepicker-popper{
    z-index: 9;
}



.container {
  background-color: #FBFBFB;
  height: 100vh;
}

.mainBox {
  width: 100%;
  height: auto;
  /* border: 2px solid rgb(128, 128, 128); */
  display: block;
  margin: auto;
  border-radius: 8px;
  background-color: #fbfbfb;
  margin-top: 20px;
  box-shadow: 1px 1px 10px rgb(228, 228, 228);
  padding:10px 0px
}

.titleBar {
  background-color: white;
  margin-top: 45px;
  display: flex;
  justify-content: space-evenly;
}

.table {
  background-color: #eeeeee;
  width: 95%;
  display: block;
  margin: auto;
}

.line {
  border-left: 2px solid black;
  height: 30px;
}

.moreInfo {
  border: 1px solid rgb(73, 73, 73);
  border-radius: 5px;
  width: 33.375em;
  height: auto;
 
  margin: auto;
  /* margin-top: 60px; */
  padding-top: 10px;
}

.guardianCard{
    width: 90%;
    margin: auto
}




.placeholder{
    font-style: normal;
}


.pointer{
  cursor: pointer;
}

.closeIconstyle{
  border: 1px solid;
  background-color: aqua;
}



.addressStyle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: aqua; */
}

.ratings{
  display: flex;
}

.rateFontSize{
  font-size: 1rem;
  margin-top: 14px;
  
}

.rateName{
  font-size: 1rem;
}

.iconStyles{
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.hoverTable:hover{
  cursor: pointer;
  background-color: #e4e4e4;
}

.editStyle{
  margin: 0px;
}

.addMargin{
  margin-left: 57%;
}


/* test styles */


.testStyle {
  border: 1px solid #7e7e7e;
  width: 90%;
  margin: auto;
  height: 100px;
}

.testNameStyle {
  border: 1px solid #7e7e7e;
  width: 95%;
  height: 30px;
  margin: auto;
}

.testBox {
  /* border: 1px solid #7e7e7e; */
  width: auto;
  /* height: auto; */
  text-align: center;
  background-color: green;
  color: white;
  /* padding: 3px; */
  margin-right: 15px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.testBoxFail {
  /* border: 1px solid #7e7e7e; */
  width: auto;
  /* height: auto; */
  text-align: center;
  background-color: gray;
  color: white;

  margin-right: 15px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.testBoxGroup {
  display: flex;
  flex-direction: row;
  margin-left: 80px;
  margin-top: 0px;
  justify-content: space-evenly;
  width: 100%;
  text-align: left;
}

.capLetter {
  text-transform: capitalize;
  /* border: 1px solid; */
  align-items: center;
  display: flex;
  height: 42px;
}

.mainContainer{
    border: 1px solid #7E7E7E;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)
}

.ratings {
  display: flex;
}

.rateFontSize {
  font-size: 1rem;
  margin-top: 14px;
}

.rateName {
  font-size: 1rem;
}


/* fonts */
.editStyle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.font20{
    font-size: 18px;
    font-weight: 550;
}

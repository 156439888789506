.formstyle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}

.forgot{
   
    font-size: 12px;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #00000080;
}

.formBtn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginBtnDiv{
    text-align: center;
    margin-top: 15px;
}

.loginBtn{
    width: 22.3em;
    background-color: #EF9000;
    border: none;
    border-radius: 5px;
    height: 25px;
}

.forgotTwo{
    display: flex;
    flex-direction: row;
}

.passbox{

   border : "1px solid red "

}





@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&display=swap');


.mainContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #F5F5F5;
    padding-left: 15px;
    padding-right: 10px;
    height: fit-content;
    width: fit-content;
    border: 1px solid black;

}

.container{
     width: "100%";
     display: flex;
     flex-direction: column;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    justify-content: space-evenly;
    align-items: center;



}
/* .container{

background-color: red ;

} */
.container >div{
    /* width:40% !important; */
    margin-right: 30px;
}

.inpDate{
    width: 120px;
    height: 35px;
    margin-top: 30px;
    border-radius: 5px;
    margin-left: 2%;
    border: 1px solid rgb(228, 228, 228);
    padding-right:10px;

}

.react-datepicker__input-container input{
    padding:2px !important
}


/* .avatarstyle{
    width: 100px;
    width: 100px;

} */







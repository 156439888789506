.moreInfo {
  /* border: 1px solid rgb(73, 73, 73); */
  border-radius: 5px;
  width: 500px;
  height: auto;

  margin: auto;
  /* margin-top: 60px; */
  padding-top: 10px;
}

.heading {
  color: #3f8889;
  font-size: 20px;
  font-weight: 500;
}

.tagName {
  display: flex;
  gap: 20px;
}

.tags {
  width: 25%;
  font-size: 16px;
  font-weight: 400;
  margin: 5px 0;
}

.profiledata {
  width: 70%;
  margin: 5px 0;
  font-size: 16px;
  font-weight: 400;
  color: #00000080;

  & span {
    /* width: 100px; */
    border: 1px solid #13868b;
    padding: 5px 10px;
    border-radius: 50px;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #13868b;
  }
}

.WhichClass {
  display: inline-block;
  border: 1px solid #032c6b;
  color: #032c6b;
  background-color: #032c6b1a;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
}

/* outer box */

.testContainer {
  box-shadow: 0px 2px 24px 0px #00000026;
  border: 1px solid #eaeaea;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
}
.topLine {
  display: flex;
  justify-content: space-between;

  & h3 {
    font-size: 16px;
    font-weight: 500;
    color: #032c6b;
    margin: 5px 0;
  }

  & p {
    font-size: 12px;
    font-weight: 400;
    color: #00000080;
    margin: 5px 0;
  }
}

.midline {
  display: flex;
  justify-content: space-between;
}

.score {
  color: #13868b;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;

  & span {
    font-size: 12px;
    font-weight: 400;
    color: #00000080;
  }
}

.timeshow {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}
/* inner box */

.parameterContainer {
  border: 1px solid #13868b80;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.testCategory {
  font-size: 16px;
  font-weight: 500;
  color: #032c6b;
  margin: 5px 0;
}

.rightContainer {
    width: 30%;
  & h3 {
    text-align: center;
    background-color: #c7e3e3;
    color: #13868b;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 25px;
  }
}

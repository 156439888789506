.bottomcon {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.boxcontainer {
  width: "56.313rem";
  height: "5.75rem";
  background-color: aqua;
}

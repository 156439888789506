.cardContainer {
  width: 400px;
  margin: auto;
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid red; */
  margin-top: 3%;
}

.loginContainer {
  /* margin-top: 100px; */
}
.welComeTwo {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0px;
  text-align: center;
}

.welComeOne {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #172b69;
  margin-top: 10px;
}

.card {
  width: 93%;
}

.logoStyles {
  margin-left: 80px;
  margin-top: 50px;
 

}

.loginButtonContainer{
    width: 100%;
    text-align: center;
    border: 1px solid #172B69;
    border-radius: 10px;
    margin-top: 15px;
    margin: auto;
}



.navbarContainer{
    min-height: 64px;
}

.bad{
   color: orange;
}


@media only screen and (max-width: 600px) {
    .navbar {
        border-bottom: "2px solid #12878b",
    }
  }
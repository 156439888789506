

.circular-class{
    position: absolute;

}

.overlay-class{
    background: #e9e9e9;  
    display: none;        
    position: absolute;   
    top: 0;              
    right: 0;         
    bottom: 0;
    left: 0;
    opacity: 0.5;
}

.content{
    padding:30px 20px;
}

.active-button{
background-color:#12878b !important;


}


.active-text{
color: "white";

}